import React from "react"
import { graphql } from "gatsby"
import "../../styles/main.scss"
import SEO from "../../components/seo"
import { CardColumns } from "../../components/CardColumns"
import ArticleCard, { Article } from "../../components/ArticleCard"
import CategoryLabel from "../../components/CategoryLabel"
import { Skeleton } from "../../components/layout/Skeleton"

export default function Blog({
  data,
}: {
  data: { category: { title: string }; allArticle: { nodes: Article[] } }
}) {
  const { category, allArticle: articles } = data

  return (
    <>
      <SEO
        title={`Artykuły z sekcji: ${category.title} | Nasze Pierwsze Dziecko`}
      />
      <Skeleton>
        <CategoryLabel title={category.title} />
        <CardColumns>
          {articles.nodes.map((article: Article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </CardColumns>
      </Skeleton>
    </>
  )
}

export const query = graphql`
  query {
    category(slug: { eq: "blog" }) {
      title
    }
    allArticle(
      filter: { category: { slug: { eq: "blog" } } }
      sort: { fields: [published_at], order: DESC }
    ) {
      nodes {
        ...ArticleNodes
      }
    }
  }
`
